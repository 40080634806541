:root {
  --sidebar-width: 25vw;
}
html {
  font-size: 14px;
}
body {
  background-color: #ffffff;
}
h1 {
  color: #585ea3;
}
a:link {
  font-size: smaller;
  color: 585ea3;
  text-decoration: none;
}

nav a:link {
  font-size:small;
}
a {
  color: #5555ff;
}
a:visited {
  color: #5555ff;
}
a:hover {
  color: #9999ff;
}

.site {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: var(--sidebar-width) auto;
}

#main {
  grid-column-start: 2;
  padding-top: 1rem;
}

.sidebar {
  position: fixed;
  width: var(--sidebar-width);
  height: 100%;
  background-color: lightsteelblue;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto minmax(0, 1fr);
}

.filter {
  padding: 2rem;
  padding-bottom: 0px;
}

#map {
  height: 100%;
  width: 100%;
}

.circle {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border-style: solid;
  font-size: 16px;
  font-weight: bold;
  background: rgba(0, 57, 128, 0.8);
  color: #dbdcdd;
  border-color: #929292;
  border-width: 2px;
}

.activeMarker {
  color: #33e43c;
  border-color: #dbdcdd;
  border-style: dashed !important;
}

#main > .navbar {
  margin-top: -1rem;
  margin-left: -1rem;
}
.navbar {
  margin-bottom: 1rem;
}
#navbarNav {
  justify-content: space-between;
}

/*curl modal*/
@media (min-width: 768px) {
  .modal-dialog {
    width: 45%;
    max-width: 1200px;
  }
}

#map-controls {
  margin-bottom: 1rem;
}

#map-controls button {
  pointer-events: auto;
}

#bounds-filter {
  pointer-events: auto;
}

#bounds-filter-container {
  margin-right: 5rem;
  margin-top: .5rem;
  display: inline-flex;
}

#bounds-filter-container h6{
 margin-top: .75rem; 
 margin-right: .25rem;
}
.non-interactive {
  pointer-events: none;
}

.active-marker {
  font-size: 2rem;
  color: #33e43c;
  text-shadow: #000 1px 1px;
}