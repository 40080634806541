.permit-modal {
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  /* left: 0;
  top: 0; */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0,0); /* Fallback color */
	margin: auto;
	padding-right: 1rem;
	padding-left: 1rem;
	width: 80%;
  transform: translate(-2.5rem,-100%);
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  transform: translate(-0.1rem,-0.5rem);
  border-top: 2rem solid rgba(0, 57, 128, 0.8);
  margin-top: -.4rem; /* offset permit-box margin */
}