
.order {
	margin-bottom: 1rem;
}

.filter-form{
	display:grid;
	grid-gap: .2rem;
	grid-template-columns: repeat(12,minmax(0,1fr));
	grid-template-areas:
	"m m m m m m m i i i i i"
	"s s s s s c c c c y y y"
	"z z z w w w w f f f f f"
	"p p p p p n n n n n o o";
}
#classification-filter {
	grid-area :m;
}
#source-filter {
	grid-area: i;
}
#street-filter {
	grid-area: s;
}
#city-filter {
	grid-area: c;
}
#state-filter {
	grid-area: y;
}
#zip-filter{
	grid-area: z;
}
#cost-filter {
	grid-area: w;
}
#size-filter {
	grid-area: f;
}
#permitdata-filter {
	grid-area: p;
}
#notes-filter {
	grid-area: n;
}
#apply-btn {
	grid-area: o;
	margin-top: 2rem;
	margin-left: .5rem;
}

#apply-btn > input {
	padding-left: 1rem;
	padding-right: 1rem;
	margin-top: .3rem;
}

i {
	margin-right: 0.25rem;
}