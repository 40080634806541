td  {
	padding: 0px;
}

.btn{
	margin: 2px;
}

.f18 {
	font-size: 16px;
}

.permit-box {
	margin: .25rem;
	margin-bottom: .75rem;
	border-collapse:separate;
	border:solid black 5px;
	border-radius:6px;
	-moz-border-radius:6px;
	width: 100%;
}

.permit-grid {
	display:grid;
	grid-gap: .5rem;
	grid-template-columns: 524px repeat(2,minmax(0,1fr)) 4vw;
	grid-template-rows: auto auto auto ;
	grid-template-areas:
"video title title side"
"video info data side"
"video actions data side";
}

.video {
	grid-area: video;
}

.video > video {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin-left: .5rem;
}

.permit-title{
	grid-area: title;
	margin-top: .5rem;
	font-size: large;
}
.permit-title button {
	margin-right: 1rem;
}
.permit-info {
	grid-area: info;
}
.permit-actions {
	grid-area: actions;
}
.permit-data {
	grid-area: data;
}
.permit-data > div {
	position: relative;
	top: 20%;
	transform: translateY(-20%);	
}
.permit-sidebar {
	grid-area: side;
	width: 4vw;
	background-color: rgba(0, 0, 0, 0.07);
	display:flex;
	flex-direction: column;
	justify-content: space-between;
}

.selected {
	border-style: dashed !important;
	box-shadow: 5px 5px 5px;
}



.permit-sidebar > h3 {
	cursor: pointer;
}

.video-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.25rem 0.25rem;
}
